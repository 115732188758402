import update from 'immutability-helper'
import { useCallback, useState, useRef, useEffect } from 'react'
import { useDrop } from 'react-dnd'
import { Table }  from './Table.js'
import { ItemTypes } from './ItemTypes.js'
import { snapToGrid as doSnapToGrid } from './snapToGrid.js'
import DraggableTable from './DraggableTable.js'
import GridItems from './GridItems.js'
import styled from 'styled-components';
import { Nav ,Card, Button, Row, Col, Modal, CardText, InputGroup, FormControl,Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

const styles = {
  width: '100%',
  height: '300px',

  position: 'relative',

  borderRadius: '0 0 5px 5px', // radius only at the bottom
  overflow: 'hidden',
}

const StyledDiv = styled.div`
  width: 100%;
  max-height: 400px;
  overflow: auto;
  border-radius: 0 0 5px 5px; // radius only at the bottom

  /* For Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    width: 10px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
`;

const StyledDivInside = styled.div`
  width: 750px;
  height: 400px;
  position: relative;
  background-color: white;

  /* Add a grid background */
  background-image: 
    linear-gradient(to right, rgba(0, 0, 0, 0.04) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.04) 1px, transparent 1px);
  background-size: 150px 150px; /* Adjust the size of the grid cells */
`;

const StyledCard = styled(Card)`
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: '0 0 5px 5px'; // radius only at the bottom
  overflow: hidden;
`;

const CardTop = styled.div`
  padding: 5px;
  background-color: #8FEBC5;
  border-radius: '5px 5px 0 0'; // radius only at the top
  position: relative;
`;

const CardMenuTop = styled.div`
  height:auto;
  padding: 5px 5px 0px 5px ;
  background-color: #8FEBC5;
  border-radius: '5px 5px 0 0'; // radius only at the top
  position: relative;
`;

const CardBot = styled.div`
  height: 100px;
  background-color: white;
  border-radius: '0 0 5px 5px'; // radius only at the top
  position: relative;
`;

const CardBotGrid = styled.div`
  height: 35px;
  background-color: #8FEBC5;
  width:100%;
  position: relative;
  padding:5px;
`;

const StyledSelect = styled.select`
  width: 85%;
  height: 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`;

const WhiteNav = styled(Nav)`
  a {
    color: white;
  }
  .nav-link.active {
    color: white;
    border-color: white;
  }
  .nav-link:hover {
    color: white;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;

export const Grid = ({ snapToGrid, 
                      selectedItem, 
                      setSelectedItem, 
                      setWidth, 
                      setHeight, 
                      all_tables,
                      setDraggingItem,
                      draggingItem,
                      }) => {
  const [selectedPlace, setSelectedPlace] = useState(false);

  const [selectedSeparator, setSelectedSeparator] = useState('Tables');

  const [tabOrPills, setTabOrPills] = useState('tabs');
  const [topDistance, setTopDistance] = useState('');

  const [isReady, setIsReady] = useState(false);


  const [grid_height, setGrid_height] = useState('');
  const [grid_width, setGrid_width] = useState('');
  
  const containerRef = useRef(null);
  const insideContainerRef = useRef(null);
  const MenuRef = useRef(null);

  const [tables, setTables] = useState([])

  const restaurantZones = useSelector(state => state.restaurantZones);
  const { zones = []} = restaurantZones;

  const [objects, setObjects] = useState([
    {
      height: 50,
      id: 246,
      isPlaced: false,
      left: 0,
      number_of_seats: "3",
      place_of_table: 7,
      restaurant: 1,
      round_or_square: "square",
      table_name: "1",
      table_number: "1",
      top: 0,
      type: "table",
      width: 50
    }
    // Add more objects here if needed
  ]);



  useEffect(() => {
    setTables(all_tables)
  },[all_tables]);

  useEffect(() => {
    if (zones.length > 0) {
      setSelectedPlace(zones[0].id);
    }
    
  },[zones])

  useEffect(() => {
    console.log('selectedPlace: ', selectedPlace);
  },[selectedPlace]);

  useEffect(() => {
    if (selectedItem && tables[selectedItem]) {
      // Retrieve width and height of the table with the ID 'selectedItem'
      const width = tables[selectedItem].width;
      const height = tables[selectedItem].height;
      console.log(`Selected Table ID: ${selectedItem}, Width: ${width}, Height: ${height}`);
      // You can use width and height as needed here
    }
  }, [selectedItem, tables]); 

  const selectItem = (value) => {
    setSelectedItem(value);
  }

  const moveTable = useCallback(
    (id, left, top, state) => {
      console.log('state: ', state !== 'tableOff' && state !== 'reset' , state)
      setTables(tables => tables.map(table => {
        if (table.id === id) {
          return {
            ...table,
            left: state === 'tableOff' ? 20 : left,
            top: state === 'tableOff' ? 20 : top,
            isPlaced: state !== 'tableOff' && state !== 'reset',
          };
        }
        return table;
      }));
    },
    [],
  );

  const [, drop] = useDrop(() => ({
    accept: ItemTypes.TABLE,
    drop(item, monitor) {
      const state = item.state;
      const delta = monitor.getDifferenceFromInitialOffset();
      
      let left = Math.round(item.left + delta.x);
      let top = Math.round(item.top + delta.y);
      
      if (snapToGrid) {
        [left, top] = doSnapToGrid(left, top);
      }

      moveTable(item.id, left, top, state);
      return undefined;
    },
  }),
  [moveTable]);

  useEffect(() => {
    drop(containerRef);
  }, [drop, containerRef, isReady]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (entries[0].contentRect.width < 297) {
        setTabOrPills('pills');
      } else {
        setTabOrPills('tabs');
      }
    });

    if (MenuRef.current) {
      resizeObserver.observe(MenuRef.current);
    }

    return (() => {
      if (MenuRef.current) {
        resizeObserver.unobserve(MenuRef.current);
      } })

  }, [isReady]);
   
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsReady(true);
    },1000);
    return () => clearTimeout(timeoutId);
  },[]);


  useEffect(() => {
    const handleScroll = () => {
      if (insideContainerRef.current) {
        const rect = insideContainerRef.current.getBoundingClientRect();
        const topDistance = insideContainerRef.current.scrollTop;
        const top = rect.top;
        
        setTopDistance(top);
        console.log('top: ', top);
      }
    };
  
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target) {
          const rect = entry.target.getBoundingClientRect();
          const top = rect.top;
          console.log('window.scrollY: ', window.scrollY);
          console.log('top: ', top);
          setTopDistance(top);
        }
      }
    });
  
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
      window.addEventListener('scroll', handleScroll);
      
      if (typeof containerRef.current.addEventListener === 'function') {
        containerRef.current.addEventListener('scroll', handleScroll);
      }
    }
  
    return () => {
      resizeObserver.disconnect(); // Disconnect the observer from all elements
  
      // Remove scroll event listeners on cleanup
      window.removeEventListener('scroll', handleScroll);
  
      if (containerRef.current && typeof containerRef.current.removeEventListener === 'function') {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerRef, insideContainerRef, isReady]); // Dependencies
  

  return (
    <Container className='no-select'>
      <StyledCard>
      <CardTop>
        <Row>
          <Col xs={6} sm={6} md={8}>
          </Col>
          <Col  xs={6} sm={6} md={4}>
            <StyledSelect value={selectedPlace} onChange={e => setSelectedPlace(parseInt(e.target.value, 10))}>
              {zones.map(p => <option key={p.id} value={p.id}>{p.place_of_table}</option>)}
            </StyledSelect>
          </Col>
        </Row>
      </CardTop>
      { tables && tables.length > 0 && 
        <StyledDiv ref={containerRef}>  
          <StyledDivInside ref={insideContainerRef}>
          {
            tables.filter(table => table.place_of_table === selectedPlace)
                  .map((table, index) => (
                    <DraggableTable key={index} 
                                    id={table.id} 
                                    {...table} 
                                    selectedItem={selectedItem} 
                                    selectItem={selectItem} 
                                    tables={tables} 
                                    moveTable={moveTable} 
                                    table={table}
                    />
                  ))
          }
          </StyledDivInside> 
        </StyledDiv>
      }
      <CardBotGrid>
      <Row>
          <Col xs={6} sm={6} md={8}>
          </Col>
          <Col  xs={6} sm={6} md={4}>
            <ActionButtons>
            <Button variant="info" size="sm" className="mr-2" style={{ fontSize: "0.8rem", padding:'3px' }}>
              Guardar
            </Button>
            </ActionButtons>
          </Col>
        </Row>
      </CardBotGrid>
    </StyledCard>
    <StyledCard>
    <CardMenuTop ref={MenuRef}>
        <Row>
          <Col xs={12} sm={12} md={12}>
          <Nav variant={tabOrPills} defaultActiveKey="link-0">
            <Nav.Item>
              <Nav.Link onClick={() => {setSelectedSeparator('Tables')}}  eventKey="link-0">Mesas</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => {setSelectedSeparator('Obj')}} eventKey="link-1">Objectos</Nav.Link>
            </Nav.Item>
          </Nav>
          </Col>
        </Row>
      </CardMenuTop>
      <CardBot>
        {tables && tables.length > 0 &&
          <GridItems
            items={tables}
            topDistance={topDistance}
            moveTable={(id, left, top, state) => moveTable(id, left, top, state)}
            selectItem={selectItem}
            selectedPlace={selectedPlace}
            selectedSeparator={selectedSeparator}
            objects={objects}
          />
        }
            
      </CardBot>
    </StyledCard>
    </Container>
  )
}
