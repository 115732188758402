import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Card, Button, Container, Row, Col, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRestaurantList, destroyItems, resetConfirmSubscription, resetRestaurantDetailsNow } from '../actions/restaurantActions';
import Loader from '../components/Loader'
import Messages from '../components/Messages'
import RestaurantsHeader from '../components/RestaurantsHeader'
import styled from 'styled-components';
import { logout } from '../actions/userActions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiCopy } from 'react-icons/fi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { fetchReservationDeactivate } from '../actions/reservationsActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket, faUtensils } from '@fortawesome/free-solid-svg-icons';

const StyledCard = styled.div`
  position:relative;
  display:flex;
  overflow:hidden;
  padding:0;
  height:150px;
  width:100%;
  flex-wrap: wrap;
  border: 1px solid #c9cdd4;
  margin:1px;
`;

const StyledCardTop = styled.div`
  width:100%;
  background-color:#58c49f;
  height:35px;
  color:white;
  font-size:1.1rem;
  padding-top:4px;
  padding-left:10px;
`;

const StyledCardBot = styled.div`
  margin: 8px;
  padding:5px;
  height:auto;
  width:100%;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;

const DeleteButtonContainer = styled.div`
  position: absolute;
  padding:4px;
  right: 1px;
  top:1px;
  font-size:10px;
`;

function RestaurantInfo() {
  const restaurantList = useSelector(state => state.restaurantList)
  const {  restaurants ,isLoading, error } = restaurantList
  const userLogin = useSelector((state) => state.userLogin);
  const [showModal, setShowModal] = useState(false);
  const [restaurantName, setRestaurantName] = useState(false);
  const [restaurantIdToDelete, setRestaurantIdToDelete] = useState(null);
  const [enteredName, setEnteredName] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  
  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const crudManagerPermissions = useSelector(state => state.crudManager)
  const {  managerInfo  } = crudManagerPermissions

  useEffect(() => {
    dispatch(fetchReservationDeactivate());
    dispatch(resetRestaurantDetailsNow());
    if (managerInfo) {
      if (managerInfo.first_time_password_change === false) {
        navigate(`/profile/update/${'first_time'}`);
      }
    }
  },[managerInfo])

  useEffect(() => {
    dispatch(resetConfirmSubscription());
  },[])
  
  useEffect(() => {
    dispatch(fetchRestaurantList());
    if (!userInfo) {
      navigate(`/login/${'null'}/${'null'}`);
    }
  }, [dispatch, navigate, userInfo]);

    // Rest of the component code...

    const handleDelete = (restaurantId, restaurant_name) => {
      setRestaurantIdToDelete(restaurantId);
      setShowModal(true);
      setRestaurantName(restaurant_name);
    };
    
    const confirmDelete = () => {
      if (enteredName === restaurantName) {
        dispatch(destroyItems(restaurantIdToDelete, 'restaurants'));
        setShowModal(false);
      } else {
        setErrorMessage(true);
      }
      
      
    };
  
    const closeModal = () => {
      setShowModal(false);
    };

    useEffect(() => {
      if (error === 'Given token not valid for any token type') {
        dispatch(logout());
      } else if (error) {
        window.location.reload();
      }
    },[error]);

  return (
    <Container>
      <ToastContainer />
      <RestaurantsHeader/>
      
      {isLoading ? 
        <Loader></Loader>
      : error ? 
        <Messages variant='danger'>{error}</Messages>
      : restaurants && Object.keys(restaurants).length > 0 ?
      <>
      <h1>Restaurantes</h1>
          {restaurants.map((restaurant) => (
          <StyledCard key={restaurant.id}>
            {!managerInfo &&
              <DeleteButtonContainer>
              <Button
                variant="danger"
                size="sm"
                className="mr-2"
                onClick={() => handleDelete(restaurant.id, restaurant.restaurant_name)}
                style={{ fontSize: "0.6rem" }} 
              >
                Apagar
              </Button>
            </DeleteButtonContainer>
            }
            
            <StyledCardTop>
            <FontAwesomeIcon icon={faUtensils} style={{color: "#ffffff",}} /> {restaurant.restaurant_name}
            </StyledCardTop>
            <StyledCardBot>
            <Row>
                <Col xs={12} md={6}>
                
                  <Card.Text>{restaurant.adress}</Card.Text>
                </Col>
                <Col xs={12} md={12}>
                  {restaurant.tutorial_step === 'buy_plan'?
                  <ActionButtons>
                    <Link to={`/choose_plan_and_pay/${restaurant.id}`}>
                      <Button variant="info" size="sm" className="mr-2" style={{ fontSize: "0.8rem" }}>
                        Escolher plano
                      </Button>
                    </Link>
                  </ActionButtons>
                  :
                  <ActionButtons>
                  <Link to={`/reservationfromrestaurant/${restaurant.id}/null/all`}>
                    <Button variant="info" size="sm" className="mr-2" style={{ fontSize: "0.8rem" }}>
                       Entrar <FontAwesomeIcon size='xl' icon={faArrowRightToBracket} style={{color: "#ffffff",}} />
                    </Button>
                  </Link>
                  <Button 
                    variant="warning"
                    size="sm" 
                    className="mr-2" 
                    style={{ fontSize: "0.8rem", paddingRight: "0.5rem" }}
                    onClick={() => navigate(`/reservations/${restaurant.id}/${'client'}/${'no_id'}`)}
                  >
                    Link de reservas
                  </Button>
                  <CopyToClipboard 
                    text={`http://www.soup.red/#/reservations/${restaurant.id}/${'client'}/${'no_id'}`}
                    onCopy={() => toast('Link copiado!', { autoClose: 1000 })}
                  >
                    <Button variant="warning" size="sm" style={{ fontSize: "0.8rem", paddingLeft: "0.5rem" }}>
                      <FiCopy />
                    </Button>
                  </CopyToClipboard>
                </ActionButtons>
                  }
                </Col>
              </Row>
            </StyledCardBot>
              
            
          </StyledCard>
        ))}
      </>   
      : <></>
      }
      <Modal show={showModal} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Apagar restaurante?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        De certeza que quer apagar o restaurante?
        <Form.Group controlId="restaurantNameInput">
          <Form.Label>Insira "{restaurantName}" para continuar.</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Restaurant Name" 
            value={enteredName} 
            onChange={(e) => setEnteredName(e.target.value)} 
          />
          {errorMessage && <p style={{color:'red'}}>Nome errado*</p>}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={confirmDelete}>
          Apagar
        </Button>
      </Modal.Footer>
    </Modal>
    </Container>
  );
}

export default RestaurantInfo;