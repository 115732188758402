import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import EstimatedTimePage from '../components/EstimatedTimeCustomersSpend';
import styled from 'styled-components';
import SubMenu from '../components/SubMenu';
import Separator from '../components/Separator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChair, faGear, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const ModernButton = styled.button`
  padding: 5px 8px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  color: black;
  border: 1px solid #e8e6e1;
`;

const WrapperTop = styled.div`
  width:100%;
  color:white;
  height:60px;
  background-color:#8FEBC5;
  border-radius: 5px 5px 0 0; 
  margin-bottom:10px;
`;

const WrapperBot = styled.div`
  width:100%;
  height:auto;
  padding:10px;
`;


const Title = styled.h6`
  padding: 20px;
  color: white;
`;

const CreateButton = styled(ModernButton)`
  position: absolute;
  top: 11px;
  right: 11px;
  background-color: #59d9d4;
  &:hover {
    background-color: #228a86;
  }
`;

const B2 = styled.div`
  background-color: #f8f9fa;
  position:relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const B2Top = styled.div`
  background-color: #58c49f;
  height:30px;
  color:white;
  width:100%;
`;

const B2Bot = styled.div`
  padding: 10px;
  max-height: 250px;
  width: 100%;
  overflow: auto;
  
  /* this is the default color of the scrollbar */
  scrollbar-color: #888 #f2f2f2;
  
  /* this is the width of the scrollbar */
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    /* this is the width of the scrollbar */
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    /* this is the color of the track, where the scrollbar moves */
    background: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    /* this is the color of the scrollbar itself */
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    /* this is the color of the scrollbar when hovered */
    background: #555;
  }
`;

const ZoneTitle = styled.h2`
  color:white;
  font-size:18px;
  padding: 5px;
`;




function RestaurantEstimatedTimes() {


    const dispatch = useDispatch();
    const params = useParams();
    const [restaurant_id, setRestaurant_id] = useState(params.restaurant_id);


  return (
            <Container>
                        <EstimatedTimePage 
                            restaurant_ids={restaurant_id}
                        />
            </Container>
  )
}

export default RestaurantEstimatedTimes