export const cancelledStatuses = [
    'Cancelled_By_Customer',
    'Cancelled_By_Customer_Warning',
    'Cancelled_By_Restaurant',
    'Cancelled_By_Expired_Code',
    'Cancelled'
];

export const cancelledDidNotAttendStatuses = [
    'Cancelled_By_Customer',
    'Cancelled_By_Customer_Warning',
    'Cancelled_By_Restaurant',
    'Cancelled_By_Expired_Code',
    'Cancelled',
    'Did_Not_Attend'
];

export function getReservationStatusTranslation(status) {
    switch (status) {
        case 'Pending_from_customer':
            return 'Reserva em curso';
        case 'Pending_from_restaurant':
            return 'Pendente de aceitação';
        case 'Edited':
            return 'Pedido de alteração';
        case 'Late':
            return 'Atraso!';
        case 'waiting_list':
            return 'Lista de espera';
        case 'Awaiting_customer_reconfirmation':
            return 'Aguarda reconfirmação';
        case 'Accepted':
        case 'Accepted_Without_Table':
        case 'Accepted_With_Table':
        case 'Accepted_edit':
        case 'Rejected_edition':
            return 'Reserva Aceite';
        case 'Attended':
            return 'Reserva concluida';
        case 'Did_Not_Attend':
            return 'Não compareceu!';
        case 'Cancelled_By_Customer':
        case 'Cancelled':
            return 'cliente cancelou';
        case 'Cancelled_By_Restaurant':
            return 'restaurante cancelou';
        case 'Cancelled_By_Expired_Code':
            return 'código expirado';
        default:
            return 'Null';
    }
}
