import React from 'react';
import { Spinner } from 'react-bootstrap';

function Loader({ theSize = 'n' }) {
  // Set the dimensions based on theSize
  const dimensions = theSize === 'n' ? '2rem' : '4rem';

  return (
    <Spinner 
      animation="border" 
      role="status" 
      className="spinner-fast" 
      style={{ width: dimensions, height: dimensions }}
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default Loader;
