import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock, faUser, faNoteSticky, faHouse } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 10px;
  width: 100%;
  justify-content: space-between;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 1%;
  border: ${(props) => (props.active || props.completed ? 'none' : '1px solid #00796b')};
  border-radius: ${(props) => (props.isFirst ? '10px 0 0 10px' : props.isLast ? '0 10px 10px 0' : '0')};
  background-color: ${(props) => (props.active || props.completed ? '#00796b' : 'transparent')};
  color: ${(props) => (props.active || props.completed ? 'white' : '#00796b')};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  height: 38px;
  max-width: 25%;
  pointer-events: ${(props) => (props.clickable ? 'auto' : 'none')};
  &:not(:last-child) {
    margin-right: -5px;
  }

  &::after {
    content: '';
    position: absolute;
    right: -7px;
    top: 0;
    bottom: 0;
    width: 15px;
    height: 100%;
    background-color: ${(props) => ((props.active || props.completed) && props.notShow ? '#00796b' : 'transparent')};
    clip-path: polygon(0 0, 100% 50%, 0 100%);
    z-index: 2;
    pointer-events: none;
  }

  &:last-child::after {
    display: none;
  }
`;

const StepperComponent = ({ startDate = '', 
                            numPeople = '', time = '', 
                            changeStep, 
                            currentStep, 
                            placeOfTable, 
                            reservation_or_waiting_list, 
                            timezone,
                            from_restaurant,
                            creating}) => {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    { icon: faCalendarAlt, label: 'Data' },
    { icon: faUser, label: 'Pessoas' },
    { icon: reservation_or_waiting_list === 'reservation' ? faClock : faHouse, label: 'Hora/sala' },
    { icon: faNoteSticky, label: 'Info' },
  ];

  const handleTabClick = (index) => {
    if (index <= activeStep || (from_restaurant)) {
      setActiveStep(index);
      changeStep(index);
    }
  };

  useEffect(() => {
    console.log('time inside stepper: ', time)
  }, [time]);



  useEffect(() => {
    setActiveStep(currentStep);
  }, [currentStep]);

  // Ensure startDate is a valid Date object, otherwise set it to null
  let validStartDate = startDate instanceof Date && !isNaN(startDate) ? startDate : null;

    const getDateInTimezone = () => {
      const now = new Date();
      const dateInTimezone = new Date(now.toLocaleString("en-US", { timeZone: timezone }));
      dateInTimezone.setHours(0, 0, 0, 0);
      return dateInTimezone;
  };

    const formatDate = (date) => {
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
    };

    const getLabelForDate = () => {
        const today = getDateInTimezone(timezone);
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        // Normalize validStartDate to remove the time component
        const startDate = new Date(validStartDate);
        startDate.setHours(0, 0, 0, 0);

        if (startDate.getTime() === today.getTime()) {
            return 'Hoje'; // Today
        } else if (startDate.getTime() === tomorrow.getTime()) {
            return 'Amanhã'; // Tomorrow
        } else {
            return formatDate(startDate); // Format as dd/MM
        }
    };


  return (
    <Container>
      {steps.map((step, index) => (
        <Tab
            key={index}
            active={index === activeStep}
            completed={index < activeStep}
            notShow={index > activeStep - 1}
            isFirst={index === 0}
            isLast={index === steps.length - 1}
            clickable={index <= activeStep || (from_restaurant)}
            onClick={() => {
              if (index <= activeStep || (from_restaurant)) {
                handleTabClick(index);
              }
            }}
          >
          <div style={{ fontSize: '14px', display: 'flex', alignItems: 'center' }}>
            {(step.label === 'Data' && validStartDate) ? (getLabelForDate()) :
             step.label === 'Pessoas' && numPeople !== ('' || 0) ? (`${numPeople} Pessoas`) : 
             step.label === 'Hora/sala' && time !== ('' || null) ? (
              <div style={{width:'50px', height:'100%'}}>
              <div style={{top:'1px', left:'9px', position:'absolute'}}>
                {time}
              </div>
              <div
                style={{
                  top: '16px',
                  left: '5px',
                  position: 'absolute',
                  fontSize: '12px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '70px', // Adjust width to fit your design
                }}
              >
                {placeOfTable.length > 10 ? `${placeOfTable.substring(0, 10)}...` : placeOfTable.endsWith('/f') ? placeOfTable.slice(0, -2): placeOfTable}
              </div>
              </div>
              
              
            ) : (
              <>
                <FontAwesomeIcon icon={step.icon} />
                <span
                  style={{
                    marginLeft: '5px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {step.label}
                </span>
              </>
            )}
            {index < activeStep && (
              <span
                style={{
                  marginLeft: '20px',
                  color: 'white',
                  fontWeight: 'bold',
                  zIndex: '3',
                }}
              >
                |
              </span>
            )}
          </div>
        </Tab>
      ))}
    </Container>
  );
};

export default StepperComponent;
